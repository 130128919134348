export { default as LogoIcon } from './logo-icon.svg';
export { default as PhonePlusIcon } from './phone-plus-icon.svg';
export { default as HexAllWeekIcon } from './hex-all-week.svg';
export { default as GreenHexagon } from './green-hex.svg';
export { default as GreenGrassHexagon } from './green-grass-hex.svg';
export { default as YellowHexagon } from './yellow-hex.svg';
export { default as YellowBananaHexagon } from './yellow-banana-hex.svg';
export { default as PrimaryCareHex } from './hex-primary-care.svg';
export { default as SexualHealthHex } from './hex-sexual-health.svg';
export { default as SkinHairCareHex } from './hex-skin-hair-care.svg';
export { default as PrescriptionHex } from './hex-prescription.svg';
export { default as UrgentCareHex } from './hex-urgent-care.svg';
export { default as MentalHealthHex } from './hex-mental-healthcare.svg';
export { default as AddictionTherpayHex } from './hex-addiction-therapy.svg';
export { default as ChronicConditionsHex } from './hex-chronic-conditions.svg';
export { default as DietAndWeightHex } from './hex-diet-and-weight.svg';
export { default as WomensHealthHex } from './hex-womens-health.svg';
export { default as MensHealthHex } from './hex-mens-health.svg';
export { default as FamilyMedicineHex } from './hex-family-medicine.svg';
export { default as PediatricCareHex } from './hex-pediatric-care.svg';

export { default as PrimaryCarePlant } from './pc-plant.svg';
export { default as UrgentCareChronicCarePlant } from './uc-cc-plant.svg';
export { default as MentalHealthCarePlant } from './mhc-plant.svg';
export { default as AddictionTherapyPediatricCarePlant } from './at-pc-plant.svg';
export { default as DietWeightPlant } from './dw-plant.svg';
export { default as WomensHealthPlant } from './wh-plant.svg';
export { default as MensHealthPlant } from './mh-plant.svg';
export { default as FamilyMedicinePlant } from './fm-plant.svg';

export { default as EmailWithConcerns } from './email_1.svg';
export { default as CarePlan } from './paper_pen_1.svg';
export { default as PrescriptionRx } from './prescibe_rx_1.svg';

export { default as Arrow } from './arrow.svg';
export { default as CircledArrow } from './circled-arrow.svg';
export { default as User } from './user-icon.svg';
export { default as MainLogo } from './agile-telehealth-logo.svg';
export { default as MobileLogo } from './agile-telehealth-mobile-logo.svg';
export { default as HamburgerMenu } from './hamburger-icon-menu.svg';
export { default as Close } from './close.svg';

export { default as LicensedProviders } from './licensed_providers.svg';
export { default as ComprehensiveCarePlans } from './paper_pen_cp.svg';
export { default as RapidResponseTime } from './time.svg';

export { default as ChatCircleText } from './chat-circle-text.svg';
export { default as Envelope } from './envelope.svg';
export { default as MapPin } from './map-pin.svg';
export { default as VideoCamera } from './video-camera.svg';
export { default as Laptop } from './laptop.svg';
export { default as Pill } from './pill-icon.svg';
export { default as Scale } from './scale-icon.svg';
export { default as PrimaryCare } from './primary-care.svg';
export { default as Check } from './check.svg';

export { default as LinkedInFooter } from './linkedin-footer.svg';
export { default as FacebookFooter } from './facebook-footer.svg';
export { default as InstagramFooter } from './instagram-footer.svg';
