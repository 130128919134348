import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/agile-telehealth-logo.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/agile-telehealth-mobile-logo.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/arrow.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/at-pc-plant.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/chat-circle-text.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/check.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/circled-arrow.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/close.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/dw-plant.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/email_1.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/envelope.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/facebook-footer.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/fm-plant.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/green-grass-hex.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/green-hex.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hamburger-icon-menu.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-addiction-therapy.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-all-week.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-chronic-conditions.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-diet-and-weight.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-family-medicine.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-mens-health.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-mental-healthcare.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-pediatric-care.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-prescription.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-primary-care.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-sexual-health.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-skin-hair-care.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-urgent-care.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/hex-womens-health.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/instagram-footer.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/laptop.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/licensed_providers.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/linkedin-footer.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/logo-icon.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/map-pin.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/mh-plant.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/mhc-plant.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/paper_pen_1.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/paper_pen_cp.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/pc-plant.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/phone-plus-icon.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/pill-icon.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/prescibe_rx_1.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/primary-care.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/scale-icon.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/time.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/uc-cc-plant.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/user-icon.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/video-camera.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/wh-plant.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/yellow-banana-hex.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/icons/yellow-hex.svg");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/images/blue-pen-2.png");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/images/blue-pen.png");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/images/gold-plant-2.png");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/images/gold-plant.png");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/images/hero-home-page.webp");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/images/telehealth.webp");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/assets/images/weight-loss-pricing.png");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/components/ServiceItem/ServiceItem.tsx");
import(/* webpackMode: "eager" */ "/Users/dflores/Documents/projects/agiletelehealth-website/src/components/WeightLossSection/styles.css")